const Price = () => {
  return (
    <div className="flex flex-col w-full gap-12 px-4 py-[64px] ">

    <div
    data-aos="fade-down"
    data-aos-easing="linear"
    data-aos-duration="1000"
    className="max-w-full md:max-w-[572px] mx-auto flex flex-col gap-4"
    >
    <div className="flex flex-col items-center gap-2 mx-auto">
      <div className="text-[24px] text-[rgb(235,188,88)] font-bold md:text-[36px] tracking-tighter">
      Exclusive Pre-Launch Offer:<br/>
      Get OXA Tokens at Just $0.025 Each!
      </div>
    </div>
    <p className="text-[rgb(205,228,247)] text-[18px]">
    Don't miss out on this limited-time opportunity to purchase OXA tokens at a special pre-launch price of only $0.025 per token. Secure your investment today before the official launch and be part of the future of seamless travel payments.
    </p>
  </div>
    <div
      data-aos="zoom-in"
      className=" px-[20px] lg:px-[180px]  flex flex-col md:flex-row justify-between"
      >
      <div className="flex flex-col">
        <div className="text-xl">Total Supply</div>
        <div className="text-[48px] font-bold leading-[52.8px] text-[rgba(2,153,255)] mt-2">
          1,000,000,000
        </div>
      </div>
      <div className="flex flex-col mt-5 mr-0 md:mt-0 md:mr-5">
        <div className="text-xl">Pre-Sale Price</div>
        <div className="text-[48px] font-bold leading-[52.8px] text-[rgba(2,153,255)] mt-2">
          $0.025
        </div>
      </div>
      <div className="flex flex-col mt-5 mr-0 md:mt-0 md:mr-5">
        <div className="text-xl">Pre-Sale Supply</div>
        <div className="text-[48px] font-bold leading-[52.8px] text-[rgba(2,153,255)] mt-2">
          10,000,000
        </div>
      </div>
    </div>
      </div>
  )
}

export default Price
