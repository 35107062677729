import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from '@solana/wallet-adapter-react'
import * as anchor from '@project-serum/anchor'
import { numberWithCommas } from '../utils'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { TOKEN_PRESALE_HARDCAP } from '../constants'
import { useEffect, useState, useMemo, useRef } from 'react'
import { BN } from "@project-serum/anchor";
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey'
import { IDL } from '../idl/token_presale'
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import {
  PRESALE_PROGRAM_PUBKEY,
  PRESALE_STATE_SEED,
  PRESALE_AUTHORITY,
  PRESALE_ID,
  PROGRAM_ID
} from '../constants'
import { SystemProgram, PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";

const PrettoSlider = styled(Slider)({
  color: 'rgba(2,153,255)',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'rgba(2,153,255)',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: 'rgba(2,153,255)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

const Stats = () => {
  const [totalBuyAmount, setTotalBuyAmount] = useState(0)
  const { publicKey } = useWallet()
  const anchorWallet = useAnchorWallet()
  const { connection } = useConnection()

  const program = useMemo(() => {
    if (anchorWallet) {
      const provider = new anchor.AnchorProvider(
        connection,
        anchorWallet,
        anchor.AnchorProvider.defaultOptions()
      )
      return new anchor.Program(IDL, PRESALE_PROGRAM_PUBKEY, provider)
    }
  }, [connection, anchorWallet])

  const getPresaleInfo = async () => {
    if (program) {
      try {
        // const [presale_state] = findProgramAddressSync(
        //   [
        //     utf8.encode(PRESALE_STATE_SEED),
        //     PRESALE_AUTHORITY.toBuffer(),
        //     new Uint8Array([PRESALE_ID]),
        //   ],
        //   program.programId
        // )
        const presalePDA = await (
          await PublicKey.findProgramAddressSync(
            [Buffer.from(PRESALE_STATE_SEED), Uint8Array.from([PRESALE_AUTHORITY])],
            PROGRAM_ID
          )
        )[0];
        const info = await program.account.presaleState.fetch(presalePDA)
        setTotalBuyAmount(info.soldTokenAmount)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => await getPresaleInfo(), 2000)
    return () => clearInterval(interval)
  }, [publicKey])

  return (
    <div className="flex flex-col mt-4">
      <div className="text-[18px]">
        Raised{' '}
        <span className="text-[rgb(235,188,88)]">
          {totalBuyAmount
            ? numberWithCommas(Number(totalBuyAmount) + 3829765)
            : numberWithCommas(3829765)}
        </span>{' '}
        of {numberWithCommas(TOKEN_PRESALE_HARDCAP)} OXA
      </div>
      <PrettoSlider
        aria-label="Volume"
        value={(Number(totalBuyAmount)+3829765) / 100 / 1000}
      />
    </div>
  )
}

export default Stats
