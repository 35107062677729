import './components.scss'

import CoinImg from '../assets/img/pre-coin.png'

const DescriptionPart = () => {
  return (
    <div
      className="w-full lg:w-[600px] min-h-[404px] flex flex-col justify-center"
      data-aos="fade-right"
    >
      <div className="flex flex-col">
        <div className="text-[42px] md:text-[72px] leading-tight text-center lg:text-left font-bold">
        Onyx Arches unveils revolutionary{" "}
        <span className="text-[rgb(235,188,88)]">OXA</span> token
        </div>
        <div className="text-[rgb(56,165,255)] text-[18px] mt-4 text-center lg:text-left">
        At Onyx Arches, we embrace cutting-edge technology to bring you the future of travel payments.
        </div>


      </div>
      {/* <a
        href="https://OXA.gitbook.io/OXA-documentation/"
        target="_blank"
        className="w-[200px]  rounded-3xl px-4 py-2 border-2 border-solid mt-6 border-[rgb(0,102,255)] text-[20px] font-semibold tracking-tight flex flex-row gap-1 items-center hover:bg-[rgb(0,102,255)] hover:text-white"
      >
        <img src="/assets/img/document.svg" width={20} />
        Read WhitePaper
      </a> */}
    </div>
  )
}

export default DescriptionPart
