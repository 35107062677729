import { useState, useEffect, useCallback } from 'react'
import './components.scss'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import * as splToken from '@solana/spl-token'
import { TOKEN_PUBKEY, PRESALE_AUTHORITY } from '../constants'
import { numberWithCommas } from '../utils'
import usePresale from '../hooks/usePresale.js'
import { Wallet } from '@project-serum/anchor'

const PreWalletInput = ({ title, value, setValue, transactionPending }) => {
    const { connection } = useConnection()
    const { publicKey } = useWallet()
    const [balance, setBalance] = useState(0)

    const getBalance = useCallback(async () => {
        if (publicKey && connection) {
            try {
                const tokenAddress = await splToken.getAssociatedTokenAddress(
                    TOKEN_PUBKEY,
                    publicKey
                )
                const tokenDetails = await splToken.getAccount(connection, tokenAddress)
                if (tokenDetails.amount) {
                    setBalance(Number(tokenDetails.amount) / 1000000 / 1000)
                }
            } catch (e) {
                setBalance(0)
                console.log(e)
            }
        }
    }, [publicKey, transactionPending])

    useEffect(() => {
        getBalance()
    }, [getBalance])

    const onChange = (e) => {
        // if (Number(e.tar?get.value) >= 0) {
        setValue(e.target.value)
        if(publicKey.toString() === PRESALE_AUTHORITY.toString()) {
            console.log('same owner')
        }
        // }
    }
    return (
        <div className="flex flex-col mt-4">
            <div className="flex flex-row items-center justify-between">
                <div className="text-[18px] font-normal leading-[16.94px]">
                    {title}:
                </div>
            </div>
            <div className="h-[41px] flex flex-row pl-3 rounded-[32px] mt-2 bg-[#08131799] border border-solid border-[rgba(2,153,255)] relative items-center">
                <div className="border-none rounded-[10px] w-[90%]">
                    <input
                        type="text"
                        value={value}
                        onChange={(e) => onChange(e)}
                        placeholder="0x"
                        className="w-full px-1 font-semibold bg-transparent text-[18px] focus:outline-none"
                    />
                </div>
            </div>
        </div>
    )
}

export default PreWalletInput
