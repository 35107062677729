import { CircularProgress } from '@mui/material'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

const Tokenomics = () => {
  return (
    <div
      className="flex flex-col w-full gap-12 px-4 py-[142px] "
      style={{
        background:
          'radial-gradient(50% 50% at 50% 50%,#131159 0%,rgb(3,1,54) 100%)',
      }}
    >
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1000"
        className="max-w-full md:max-w-[572px] mx-auto flex flex-col gap-4"
      >
        <div className="flex flex-col items-center gap-2 mx-auto">
          <div className="text-[32px] text-[rgb(235,188,88)] font-bold md:text-[52px] leading-[62.4px] tracking-tighter">
            Key Tokenomics
          </div>
        </div>
        <p className="text-[rgb(205,228,247)] text-[18px]">
          Deep dive into the key aspects of our supply, geared to enable growth,
          longevity and community.
        </p>
      </div>
      <div className="flex flex-row flex-wrap justify-center gap-8 max-w-[1200px] mx-auto">
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={20} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Seed</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              20% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-right"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={1} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Private round 1</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              1% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={1} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Private round 2</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              1% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-right"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={2.5} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">IDO (Public)</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              2.5% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={10} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Development</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              10% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-right"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={15} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Marketing</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              15% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={7} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Strategic Partnerships</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              7% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={5} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Staking rewards</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              5% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={12} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Team & Operations</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              12% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={11.5} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Liquidity</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              11.5% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={5} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Advisors</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              5% of total supply
            </p>
          </div>
        </div>
        <div
          data-aos="flip-left"
          className="w-[160px] flex flex-col gap-[18.9px] items-center"
        >
          <CircleProgressBar percent={10} />
          <div className="flex flex-col gap-[7.56px]">
            <div className="text-xl font-semibold">Reserve & Foundation</div>
            <p className=" leading-[21px] text-[rgb(150,208,255)]">
              10% of total supply
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const CircleProgressBar = ({ percent }) => {
  return (
    <div className={`progress`}>
      <span
        className="title timer"
        data-from="0"
        data-to={percent}
        data-speed="1800"
      >
        {percent}
      </span>
      <div className="overlay"></div>
      <div className={`left animate${percent * 10}`}></div>
      <div className="right"></div>
    </div>
  )
}
export default Tokenomics
