import { useState, useCallback, useEffect } from 'react'
import './pages.css'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import * as splToken from '@solana/spl-token'
import { toast } from "react-toastify";
import { TOKEN_DECIMAL, TOKEN_PUBKEY } from '../constants'
import usePresale from '../hooks/usePresale.js'
import { Icon, IconType } from '../components/icons'
import { Divider } from '@mui/material'
import { numberWithCommas } from '../utils'

const Claim = () => {
  const { claimToken, transactionPending, buyAmount, claimedAmount, endTime, claimTime } =
    usePresale();
  const [available, setAvailable] = useState(true);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const current = Date.now();
    // const end_time = new Date(2024, 5, 4, 3, 0, 0);
    const end_time = new Date(Number(endTime) * 1000);
    // end_time.setMonth(5);
    // end_time.setDate(4);
    let startTime = new Date();
    if (new Date().getDate() >= new Date(end_time).getDate()) startTime.setDate(20);
    else {
      startTime.setMonth(new Date().getMonth() - 1);
      startTime.setDate(20);
    }
    if (new Date().getTime() >= new Date(claimTime).getTime() && new Date(claimTime).getTime() >= new Date(startTime).getTime()) setAvailable(false);
    else {
      
      let total_vesting_period = 18 * 30 * 24 * 60 * 60 * 1000;
      // let total_vesting_period = 18 * 5 * 60 * 1000;

      let first_month_vesting = Number(buyAmount) * 7 / 100;
      let monthly_vesting = Number(buyAmount) * 93 / 100 / 17;
      let vested_amount = 0;
      if (current >= end_time.getTime() + total_vesting_period) {
        vested_amount = Number(buyAmount);
      } else {
        let months_passed = (current - end_time.getTime()) / (30 * 24 * 60 * 60 * 1000);
        // let months_passed = parseInt((current - end_time.getTime()) / (5 * 60 * 1000));
        if (months_passed == 0) {
          vested_amount = first_month_vesting;
        } else {
          vested_amount = first_month_vesting + monthly_vesting * months_passed;
        }
      }
      setAmount((vested_amount - Number(claimedAmount)).toFixed(0));
    }
  }, []);
  const onClaimToken = () => {
    const current = Date.now()
    if (!available) {
      toast.error("You already claimed");
      return;
    }
    const oneMonthInSeconds = 30 * 24 * 60 * 60;
    const oneMonthInSecondsTest = 5 * 60;
    const currentTime = Number(current / 1000);
    if (current < endTime * 1000) {
      toast.error("The presale period has not expired");
    } else {
      // if ((currentTime - claimTime) < oneMonthInSecondsTest) {
      //   toast.error('The claim period has not expired.')
      // } else {
      claimToken();
      // }

    }
  }

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}:${month}:${day}:${hours}:${minutes}`;
  };


  return (
    <div
      className="w-full flex flex-col items-center mt-[160px]"
      data-aos="fade-up"
      data-aos-anchor-placement="center-center"
    >
      <div className="w-full md:w-[503px] flex flex-col">
        <div className=" font-bold text-[32px] md:text-[52px] leading-[62.4px] tracking-tight">
          Claim your <span className="text-[rgb(235,188,88)]">$OXA</span>
        </div>
        <div className="mt-4 text-[18px] tracking-tight text-[rgb(205,228,247)]">
          Claim your $OXA tokens today and unlock exclusive benefits soon.
        </div>
      </div>
      <div className="w-full md:w-[550px] border-[3px] border-solid border-[rgba(2,153,255)] bg-[rgb(5,4,80)] p-6 rounded-3xl flex flex-col mt-6">
        <div className="flex flex-col">
          <p className="text-[18px]">Your claimable amount</p>
          <div className="w-full h-0 border border-[rgba(69,204,255,.2)] mt-6" />
          {Date.now() - endTime * 1000 > 0 && (
            <div className="h-[20px] flex flex-col items-center justify-center mt-6">
              {/* <p className="text-[18px]">Claimable Amount: {amount}</p> */}
              <p className="text-[18px]">Last clamed time: {Number(claimTime) == 0 ? '' : formatTimestamp(claimTime)}</p>

            </div>
          )}
          <div className="h-[34px] flex flex-row items-center justify-center mt-6">
            <img src="/assets/img/oxa.webp" width={32} />
            <div className="ml-2 text-2xl">
              {numberWithCommas(
                Number(
                  (buyAmount - claimedAmount)
                ).toFixed(2)
              )}
            </div>
          </div>
        </div>
        {transactionPending ? (
          <div className="flex flex-row items-center justify-center mt-6">
            <Icon type={IconType.LOADING} className="w-14 h-14" />
          </div>
        ) : (
          <button
            className="h-10 rounded-3xl  text-[20px] bg-[rgba(2,153,255)] font-semibold mt-6"
            onClick={onClaimToken}
          // disabled={!available}
          >
            Claim now
          </button>
        )}
      </div>
    </div>
  )
}

export default Claim
