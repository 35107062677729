import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import { useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import { BN } from "@project-serum/anchor";
import { IDL } from "../idl/token_presale";
import { findProgramAddressSync } from "@project-serum/anchor/dist/cjs/utils/pubkey";
import {
  getAccount,
  getOrCreateAssociatedTokenAccount,
  getAssociatedTokenAddressSync,
  transfer,
  mintTo,
  createMint
} from "@solana/spl-token";
import {
  PROGRAM_ID,
  BUYER_TOKEN_HARDCAP,
  PRESALE_AUTHORITY,
  PRESALE_ID,
  PRESALE_PROGRAM_PUBKEY,
  PRESALE_STATE_SEED,
  GLOBAL_STATE_SEED,
  VAULT_STATE_SEED,
  PRICE_PER_TOKEN,
  PRICE_DECIMAL,
  TOKEN_DECIMAL,
  TOKEN_PRESALE_HARDCAP,
  TOKEN_PUBKEY,
  USER_STATE_SEED,
  SOL_TOKEN_PUBKEY,
  USDC_TOKEN_PUBKEY,
  USDT_TOKEN_PUBKEY,
  JUP_TOKEN_PUBKEY,
  SOL_PRICEFEED_ID,
  JUP_PRICEFEED_ID,
  START_TIME,
  END_TIME,
  DEAD_TIME,
  THOUSAND,
  HUNDRED,
  USDT_DECIMAL
} from "../constants";
import { toast } from "react-toastify";
import { PublicKey, Connection, Transaction, Keypair, VersionedTransaction, TransactionMessage, SystemProgram } from "@solana/web3.js";
import { utf8 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import { ASSOCIATED_PROGRAM_ID } from "@project-serum/anchor/dist/cjs/utils/token";

import { parsePriceData } from "@pythnetwork/client"

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default function usePresale() {
  const { publicKey } = useWallet();
  const wallet = useWallet();
  const anchorWallet = useAnchorWallet();
  const { connection } = useConnection();
  const [transactionPending, setTransactionPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [claimTime, setClaimTime] = useState(0);
  const [buyAmount, setBuyAmount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [totalBuyAmount, setTotalBuyAmount] = useState(0);

  const program = useMemo(() => {
    if (anchorWallet) {
      const provider = new anchor.AnchorProvider(
        connection,
        anchorWallet,
        anchor.AnchorProvider.defaultOptions()
      );
      return new anchor.Program(IDL, PRESALE_PROGRAM_PUBKEY, provider);
    }
  }, [connection, anchorWallet]);

  useEffect(() => {

    const getpresaleState = async () => {
      if (program && !transactionPending) {
        try {
          setLoading(true);
          // const [presale_state, presale_bump] = findProgramAddressSync(
          //   [
          //     utf8.encode(PRESALE_STATE_SEED),
          //     PRESALE_AUTHORITY.toBuffer(),
          //     new Uint8Array([PRESALE_ID]),
          //   ],
          //   program.programId
          // );
          const presalePDA = await getPresalePDA(PRESALE_AUTHORITY);
          const info = await program.account.presaleState.fetchNullable(presalePDA);
          setStartTime(info.startTime);
          setEndTime(info.endTime);
          setTotalBuyAmount(info.soldTokenAmount);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    const getuserState = async () => {
      if (program && publicKey && !transactionPending) {
        try {
          setLoading(true);
          // const [userState, userBump] = findProgramAddressSync(
          //   [
          //     utf8.encode(USER_STATE_SEED),
          //     PRESALE_AUTHORITY.toBuffer(),
          //     publicKey.toBuffer(),
          //     new Uint8Array([PRESALE_ID]),
          //   ],
          //   program.programId
          // );
          const userPDA0 = await getUserPDA(publicKey, 0);
          const info = await program.account.userState.fetch(userPDA0);
          setBuyAmount(info.buyTokenAmount);
          setClaimedAmount(info.claimAmount);
          setClaimTime(info.claimTime);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    getpresaleState();
    getuserState();
  }, [publicKey, program, transactionPending, connection, anchorWallet]);

  const getPrice = async (tokenSymbol) => {
    // if (program && publicKey) {
    //   try {
    //     if (tokenSymbol === "USDT" || tokenSymbol === "USDC") return 1;
    //     const price_feed_id = tokenSymbol === "SOL" ? SOL_PRICEFEED_ID : tokenSymbol === "JUP" ? JUP_PRICEFEED_ID : null
    //     if (!price_feed_id) return 0
    //     let {data} = await connection.getAccountInfo(price_feed_id) || {};
    //     if (!data) return 0
    //     const priceData = parsePriceData (data)
    //     if (priceData && priceData.aggregate && priceData.aggregate.price) {return priceData.aggregate.price}
    //     return 0
    //   }
    //   catch {
    //       return 0;
    //   }
    // } else {return 0}
    return 1;
  }

  //Jerry
  const getGlobalPDA = async (owner) => {
    return (
      await PublicKey.findProgramAddress(
        [Buffer.from(GLOBAL_STATE_SEED), owner.toBuffer()],
        PROGRAM_ID
      )
    )[0];
  };
  const getPresalePDA = async (identifier) => {
    return (
      await PublicKey.findProgramAddressSync(
        [Buffer.from(PRESALE_STATE_SEED), Uint8Array.from([identifier])],
        PROGRAM_ID
      )
    )[0];
  };
  const getUserPDA = async (user, identifier) => {
    return (
      await PublicKey.findProgramAddressSync(
        [Buffer.from(USER_STATE_SEED), user.toBuffer(), Uint8Array.from([identifier])],
        PROGRAM_ID
      )
    )[0];
  };
  const getVaultPDA = (mintKeypair, owner) => {
    return getAssociatedTokenAddressSync(
      mintKeypair,
      owner,
      true
    );
  };
  const getVaultSPDA = async () => {
    return (
      await PublicKey.findProgramAddressSync(
        [Buffer.from(VAULT_STATE_SEED)],
        PROGRAM_ID
      )
    )[0];
  };

  async function send(connection, wallet, transaction) {

    const txHash = await sendTransaction(connection, wallet, transaction);
    if (txHash != null) {
      let confirming_id = showToast("Confirming Transaction ...", 3000, 2);
      let res = await connection.confirmTransaction(txHash);
      toast.dismiss(confirming_id);
      if (res.value.err) {
        showToast("Send Transaction Failed", 2000, 1);
      } else {
        showToast("Transaction Confirmed", 2000);
      }
    } else {
      showToast("Transaction Failed", 2000, 1);
    }
    return txHash;
  }

  async function sendInitialProgram(connection, wallet, transaction) {

    const txHash = await sendTransaction(connection, wallet, transaction);

    if (txHash != null) {
      let confirming_id = showToast("Confirming Transaction ...", 3000, 2);
      let res = await connection.confirmTransaction(txHash);
      toast.dismiss(confirming_id);
      if (res.value.err) {
        showToast("Send Transaction Failed", 2000, 1);
      } else {
        showToast("Transaction Confirmed", 2000);
      }
    } else {
      showToast("Transaction Failed", 2000, 1);
    }
    return txHash;
  }

  async function sendTransaction(connection, wallet, transaction) {
    if (publicKey === null || wallet.signTransaction === undefined) {
      return null;
    }
    try {
      const messageV0 = new TransactionMessage({
        payerKey: publicKey,
        recentBlockhash: (await connection.getLatestBlockhash("finalized")).blockhash,
        instructions: [transaction]
      }).compileToV0Message([]);

      const versionedTransaction = new VersionedTransaction(messageV0)
      const signedTransaction = await wallet.signTransaction(versionedTransaction);
      console.log(await connection.simulateTransaction(signedTransaction));
      const txid = await connection.sendTransaction(signedTransaction, {
        skipPreflight: true,
        maxRetries: 20,
      })
      return txid;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
  const showToast = (txt, duration = 3000, id) => {
    let type = toast.success;
    if (id === 1) type = toast.error;
    if (id === 2) type = toast.info;

    let autoClose = duration;
    if (duration < 0) {
      autoClose = false;
    }

    return toast.error(txt, {
      position: "top-right",
      autoClose,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      type,
      theme: "colored",
    });
  };
  ////////////////////end
  const initializeAccount = async () => {
    let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
    let vaultPDA = await getVaultPDA(TOKEN_PUBKEY, publicKey);
    let vaultSPDA = await getVaultSPDA();
    const tx = await program.methods
      .initialize()
      .accounts({
        authority: publicKey,
        globalState: globalPDA,
        vaultState: vaultSPDA,
        tokenMint: TOKEN_PUBKEY,
        quoteTokenMint: USDC_TOKEN_PUBKEY,
        systemProgram: SystemProgram.programId
      })
      .instruction();
    return await send(connection, wallet, tx);
  }
  const createPresale = async () => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);
        const presalePDA = await getPresalePDA(0);
        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
        let tx = await program.methods
          .createPresale(new BN(1), new BN(25), new BN(THOUSAND), new BN(250), new BN(new Date(START_TIME).getTime() / 1000), new BN(new Date(END_TIME).getTime() / 1000))
          .accounts({
            authority: publicKey,
            globalState: globalPDA,
            presaleState: presalePDA,
            systemProgram: SystemProgram.programId,
          })
          .instruction();
        // toast.success("Successfully created presale.");
        return await sendInitialProgram(connection, wallet, tx);
        // return false;
      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };

  const updatePresale = async () => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);
        const presalePDA = await getPresalePDA(0);
        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
        let tx = await program.methods
          .updatePresale(0, new BN(1), new BN(25), new BN(THOUSAND), new BN(250), new BN(new Date(START_TIME).getTime() / 1000), new BN(new Date(END_TIME).getTime() / 1000))
          .accounts({
            authority: publicKey,
            globalState: globalPDA,
            presaleState: presalePDA,
            systemProgram: SystemProgram.programId,
          })
          .instruction();
        return await send(connection, wallet, tx);
        // toast.success("Successfully updated presale.");
      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };




  const depositToken = async () => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);
        const ownerPresaleATA = await getVaultPDA(TOKEN_PUBKEY, publicKey);
        const presalePDA = await getPresalePDA(0);
        const presaleATA = await getVaultPDA(TOKEN_PUBKEY, presalePDA);

        let info = await getAccount(connection, ownerPresaleATA);
        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
        const tx = await program.methods
          .depositToken(0, new BN(THOUSAND))
          .accounts({
            authority: publicKey,
            globalState: globalPDA,
            presaleState: presalePDA,
            tokenMint: TOKEN_PUBKEY,
            presaleTokenAccount: presaleATA,
            authorityTokenAccount: ownerPresaleATA,
            tokenProgram: anchor.utils.token.TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
            systemProgram: SystemProgram.programId
          }).instruction();
        const result = await send(connection, wallet, tx);
        if (result) {
          info = await getAccount(connection, presaleATA);
          info = await getAccount(connection, ownerPresaleATA);
        }

        return result;
      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };

  const buyToken = async (amount) => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);
        const userPDA0 = await getUserPDA(publicKey, 0);
        const presalePDA = await getPresalePDA(0);
        const usdcATA = await getVaultPDA(USDC_TOKEN_PUBKEY, presalePDA);
        const userUsdcATA0 = await getVaultPDA(USDC_TOKEN_PUBKEY, publicKey);

        let info = await getAccount(connection, userUsdcATA0);

        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);

        ////////////////////
        info = await program.account.presaleState.fetchNullable(presalePDA);
        ////////////////////
        const tx = await program.methods
          .buyToken(0, new BN(amount))
          .accounts({
            user: publicKey,
            authority: PRESALE_AUTHORITY,
            globalState: globalPDA,
            presaleState: presalePDA,
            userState: userPDA0,
            quoteTokenMint: USDC_TOKEN_PUBKEY,
            quoteTokenAccount: usdcATA,
            userTokenAccount: userUsdcATA0,
            tokenProgram: anchor.utils.token.TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
          })
          .signers([wallet])
          .instruction();
        const result = await send(connection, wallet, tx);
        if (result) {

          info = await getAccount(connection, userUsdcATA0);
          info = await getAccount(connection, usdcATA);
          toast.success("Token purchase was successful.");

          // ref fetch
          const ref = getCookie('ref') || "refless"
          fetch(`https://ps.onyxarches.com/?ref=${ref}&usdt=${amount}&tx=${result}`, {
            method: "GET",
          });
          
          info = await program.account.presaleState.fetchNullable(presalePDA);
        }

        return result;


      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };

  const setPresale = async (buyerWalletAddress, amount) => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);
        const buyerPublicKey = new PublicKey(buyerWalletAddress.toString())
        const userPDA0 = await getUserPDA(buyerPublicKey, 0);
        // const userPDA0 = await getUserPDA(publicKey, 0);
        const presalePDA = await getPresalePDA(0);
        const usdcATA = await getVaultPDA(USDC_TOKEN_PUBKEY, presalePDA);
        const userUsdcATA0 = await getVaultPDA(USDC_TOKEN_PUBKEY, publicKey);

        let info = await getAccount(connection, userUsdcATA0);

        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);

        ////////////////////
        info = await program.account.presaleState.fetchNullable(presalePDA);
        ////////////////////
        
        console.log("buyerWalletAddress", buyerWalletAddress)
        console.log('amount', amount)
        const tx = await program.methods
          .setPresale(0, buyerPublicKey, new BN(amount))
          .accounts({
            authority: PRESALE_AUTHORITY,
            globalState: globalPDA,
            presaleState: presalePDA,
            userState: userPDA0,
            systemProgram: SystemProgram.programId,
          })
          .signers([wallet])
          .instruction();
        const result = await send(connection, wallet, tx);
        if (result) {

          info = await getAccount(connection, userUsdcATA0);
          info = await getAccount(connection, usdcATA);
          toast.success("Token purchase was successful.");
          info = await program.account.presaleState.fetchNullable(presalePDA);
        }

        return result;


      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };

  const claimToken = async () => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);

        const userPresaleATA0 = await getVaultPDA(TOKEN_PUBKEY, publicKey);

        const presalePDA = await getPresalePDA(0);
        const presaleATA = await getVaultPDA(TOKEN_PUBKEY, presalePDA);
        let info = await getAccount(connection, presaleATA);
        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
        const userPDA0 = await getUserPDA(publicKey, 0);

        const tx = await program.methods
          .claimToken(0)
          .accounts({
            user: publicKey,
            globalState: globalPDA,
            presaleState: presalePDA,
            userState: userPDA0,
            tokenMint: TOKEN_PUBKEY,
            presaleTokenAccount: presaleATA,
            userTokenAccount: userPresaleATA0,
            tokenProgram: anchor.utils.token.TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
          })
          .signers([wallet])
          .instruction();


        const result = await send(connection, wallet, tx);
        if (result) {

          info = await getAccount(connection, userPresaleATA0);
          info = await getAccount(connection, presaleATA);
        }

        return result;
      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };

  const withdrawToken = async () => {
    if (program && publicKey) {
      try {
        setTransactionPending(true);
        const ownerUsdcATA = await getVaultPDA(USDC_TOKEN_PUBKEY, PRESALE_AUTHORITY);
        const presalePDA = await getPresalePDA(0);
        const presaleATA = await getVaultPDA(TOKEN_PUBKEY, presalePDA);
        const usdcATA = await getVaultPDA(USDC_TOKEN_PUBKEY, presalePDA);
        let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
        let info = await getAccount(connection, ownerUsdcATA);
        info = await getAccount(connection, usdcATA);

        const withdrawUSDT = Number(info.amount) / 1000 / 1000;
        const vaultSPDA = await getVaultSPDA();
        let tx = await program.methods
          .withdrawToken(0, new BN(withdrawUSDT))
          .accounts({
            authority: PRESALE_AUTHORITY,
            globalState: globalPDA,
            presaleState: presalePDA,
            vaultState: vaultSPDA,
            tokenMint: USDC_TOKEN_PUBKEY,
            quoteTokenAccount: usdcATA,
            authorityTokenAccount: ownerUsdcATA,
            tokenProgram: anchor.utils.token.TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
          })
          .instruction();
        const result = await send(connection, wallet, tx)

        if (result) {
          info = await getAccount(connection, usdcATA);
          info = await getAccount(connection, ownerUsdcATA);
        }
        return result;

      } catch (error) {
        console.log(error);
        toast.error(error.toString());
        return false;
      } finally {
        setTransactionPending(false);
      }
    }
  };
  const rescuedToken = async () => {
    const ownerPresaleATA = await getVaultPDA(TOKEN_PUBKEY, publicKey);
    const presalePDA = await getPresalePDA(0);
    const presaleATA = await getVaultPDA(TOKEN_PUBKEY, presalePDA);
    let globalPDA = await getGlobalPDA(PRESALE_AUTHORITY);
    const vaultSPDA = await getVaultSPDA();

    let info = await getAccount(connection, ownerPresaleATA);
    info = await getAccount(connection, presaleATA);
    const resueTokenAmount = (Number(info.amount) / 10000 / 100000) - Number(totalBuyAmount);
    const tx = await program.methods
      .rescueToken(0, new BN(resueTokenAmount))
      .accounts({
        authority: publicKey,
        globalState: globalPDA,
        presaleState: presalePDA,
        vaultState: vaultSPDA,
        tokenMint: TOKEN_PUBKEY,
        presaleTokenAccount: presaleATA,
        authorityTokenAccount: ownerPresaleATA,
        tokenProgram: anchor.utils.token.TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      })
      .instruction();


    info = await getAccount(connection, presaleATA);
    info = await getAccount(connection, ownerPresaleATA);
    return await send(connection, wallet, tx)
  }



  return {
    createPresale,
    depositToken,
    buyToken,
    claimToken,
    getPrice,
    withdrawToken,
    startTime,
    endTime,
    claimTime,
    buyAmount,
    claimedAmount,
    totalBuyAmount,
    transactionPending,
    rescuedToken,
    initializeAccount,
    updatePresale,
    setPresale,
  };
}
