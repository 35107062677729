import DescriptionPart from '../components/descriptionPart'
import PresaleCard from '../components/presaleCard'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  PRESALE_AUTHORITY,
  TOKEN_PUBKEY,
  USDT_TOKEN_PUBKEY,
  USDC_TOKEN_PUBKEY,
  JUP_TOKEN_PUBKEY,
  JUP_PRICEFEED_ID,
  TOKEN_PRESALE_HARDCAP,
} from '../constants'

import usePresale from '../hooks/usePresale'

import './pages.css'

const PresalePart = () => {
  const { publicKey } = useWallet()
  const {
    createPresale,
    depositToken,
    claimToken,
    withdrawToken,
    buyToken,
    initializeAccount,
    updatePresale,
  } = usePresale()


  const onInitializeAccount = async () => {
    await initializeAccount()
  }

  const onCreatePresale = async () => {
    await createPresale()
  }

  const onDepositToken = async () => {
    await depositToken()
  }

  const onBuyToken = async () => {
    await buyToken(0.1)
  }

  const onWithdrawToken = async (withdrawingToken) => {
    await withdrawToken()
  }

  const onUpdatePresale = async () => {
    await updatePresale();
  };


  return (
    <div className="relative w-full display-flex justify-content-center align-items-center py-6 md:py-[100px]">

      <div className="absolute inset-0 overflow-hidden pattern" />

      {publicKey && publicKey.toBase58() === PRESALE_AUTHORITY.toBase58() && (
        <div className="grid items-center grid-cols-3 gap-2 mb-5 z-10 relative">
          <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={onInitializeAccount}
          >
            Initialize Account
          </button>
          <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={onCreatePresale}
          >
            Create Presale
          </button>
          {/* <button

                        className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                        onClick={onBuyToken}
                    >
                        Buy Token
                    </button> */}

          <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={() =>
              onDepositToken()
            }
          >
            Deposit OXA Token
          </button>
          {/* <button

                        className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                        onClick={() => onDepositToken(USDT_TOKEN_PUBKEY, JUP_PRICEFEED_ID, 1)}
                    >
                        Deposit USDTToken
                    </button> */}
          {/* <button
                        className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                        onClick={() => onDepositToken(USDC_TOKEN_PUBKEY, JUP_PRICEFEED_ID, 1)}
                    >
                        Deposit USDCToken
                    </button>
                    <button
                        className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                        onClick={() => onDepositToken(JUP_TOKEN_PUBKEY, JUP_PRICEFEED_ID)}
                    >
                        Deposit JUPToken
                    </button> */}
          {/* <button
                        className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                        onClick={onUpdatePresale}
                    >
                        Update Presale
                    </button>
                    <button
                        className="px-5 py-2 bg-[#d00711] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
                        onClick={onClaimClub}
                    >
                        Claim Club
                    </button> */}

          {/* <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={onWithdrawSol}
          >
            Withdraw Token
          </button> */}
          {/* <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={() => onWithdrawToken(TOKEN_PUBKEY)}
          >
            Withdraw MintToken
          </button> */}
          {/* <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={() => onWithdrawToken(USDT_TOKEN_PUBKEY)}
          >
            Withdraw USDTToken
          </button> */}
          <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={() => onWithdrawToken(USDC_TOKEN_PUBKEY)}
          >
            Withdraw USDT
          </button>
          {/* <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={() => onWithdrawToken(JUP_TOKEN_PUBKEY)}
          >
            Withdraw JUPToken
          </button> */}
          <button
            className="px-5 py-2 bg-[#0066FF] rounded-full text-[#eff3f6] font-inter text-sm font-bold"
            onClick={() => onUpdatePresale()}
          >
            Update Presale
          </button>
        </div>
      )}
      <div className="flex flex-col items-center justify-center px-4 lg:flex-row gradient-bg">
        <DescriptionPart />
        <div className='justify-center flex flex-col lg:ml-[50px] xl:ml-[206px]'>
        <p className="text-2xl md:text-3xl leading-tight font-bold w-full md:w-[488px]">
        Always use Solana network when transferring USDT to your wallet for purchases
        </p>
        <PresaleCard />
        </div>
      </div>
    </div>
  )

}

export default PresalePart
