const Dive = () => {
  return (
    <div className="bg-[#04023a] py-[160px]  w-full flex flex-col mt-[160px]">
      <div
        className="max-w-[731px] mx-auto flex flex-col gap-4"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
      >
        <div className="flex flex-col items-center mx-auto">
          <div className="text-[32px] md:text-[52px] leading-[62.4px] font-bold text-[rgb(235,188,88)] tracking-tighter">
            Key features of OXA
          </div>
        </div>
        <p className="mt-2 text-[rgb(205,228,247)] text-[18px]">
          Soon to be integrated across all our features, $OXA empowers you to
          navigate the crypto landscape with confidence.
        </p>
      </div>
      <div className="flex flex-col justify-center gap-10 mt-12 max-lg:items-center lg:flex-row">
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className="w-[237px] flex flex-col gap-4 items-center"
        >
          <div className="w-[180px] h-[180px] p-3 rounded-[99px] bg-gradient-to-t from-gray-600/5 to-white/5">
            <div className="w-full h-full rounded-[99px] bg-[#68EAF20F] flex items-center justify-center">
              <img src="/assets/img/secure.webp" />
            </div>
          </div>
          <div className="text-2xl font-semibold tracking-tight">
            Fast & Secure
          </div>
          <p className=" leading-[21px] text-[rgb(150,208,255)]">
            Payments are processed with seconds on the Onyx Arches platform
          </p>
        </div>
        <div
          data-aos="zoom-in"
          className="w-[237px] flex flex-col gap-4 items-center"
        >
          <div className="w-[180px] h-[180px] p-3 rounded-[99px] bg-gradient-to-t from-gray-600/5 to-white/5">
            <div className="w-full h-full rounded-[99px] bg-[#68EAF20F] flex items-center justify-center">
              <img src="/assets/img/conversion.webp" />
            </div>
          </div>
          <div className="text-2xl font-semibold tracking-tight">
            Crypto Payments
          </div>
          <p className="leading-[21px] text-[rgb(150,208,255)]">
            Real-time conversion at competitive cryptocurrency exchange rates
          </p>
        </div>
        {/* <div
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className="w-[237px] flex flex-col gap-4 items-center"
        >
          <div className="w-[180px] h-[180px] p-3 rounded-[99px] bg-gradient-to-t from-gray-600/5 to-white/5">
            <div className="w-full h-full rounded-[99px] bg-[#68EAF20F] flex items-center justify-center">
              <img src="/assets/img/wallet.webp" />
            </div>
          </div>
          <div className="text-2xl font-semibold tracking-tight">
            Multi-Crypto Online Wallet
          </div>
          <p className=" leading-[21px] text-[rgb(150,208,255)]">
            Safely store popular cryptocurrencies like Bitcoin, ETH, XRP on an
            encrypted online wallet
          </p>
        </div> */}
        <div
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className="w-[237px] flex flex-col gap-4 items-center"
        >
          <div className="w-[180px] h-[180px] p-3 rounded-[99px] bg-gradient-to-t from-gray-600/5 to-white/5">
            <div className="w-full h-full rounded-[99px] bg-[#68EAF20F] flex items-center justify-center">
              <img src="/assets/img/transaction.webp" />
            </div>
          </div>
          <div className="text-2xl font-semibold tracking-tight">
            For Travel and Stays
          </div>
          <p className=" leading-[21px] text-[rgb(150,208,255)]">
            Pay for travel and accommodation related expenses across the globe
          </p>
        </div>
      </div>
    </div>
  )
}

export default Dive
