const Copyright = () => {
  return (
    <div className="mx-[16px] h-[69px] px-px pt-px overflow-hidden">
      <div className="w-full h-full  flex flex-row items-center justify-center text-sm text-[rgb(191,212,234) font-[inter]">
        © Onyx Arches 2024
      </div>
    </div>
  )
}

export default Copyright
