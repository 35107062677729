import { Divider } from '@mui/material'

const HowTo = () => {
  return (
    <div className="px-4  w-full flex flex-col my-[160px]">
      <div
        data-aos="zoom-out-down"
        className="max-w-[572px] mx-auto flex flex-col"
      >
        <div className="flex flex-col items-center gap-2 mx-auto">
          <div className="text-[32px] text-[rgb(235,188,88)] font-bold md:text-[52px] leading-[62.4px] tracking-tighter">
            How To Buy Token
          </div>
        </div>
        <p className="mt-4 text-[rgb(205,228,247)] text-[18px]">
          Deep dive into the key aspects of our supply, geared to enable growth,
          longevity and community.
        </p>
      </div>
      <div className="h-[490px] flex flex-row md:gap-[20px] lg:gap-[96px] justify-center mt-12">
        <div className="flex-row items-center hidden md:flex">
          <div className="md:w-[240px] md:h-[240px] lg:w-[360px] lg:h-[360px]">
            <img src="/assets/img/oxa.webp" />
          </div>
        </div>
        <div className="w-full md:min-w-[410px] md:w-[410px] flex flex-row gap-5">
          <div className="flex flex-col items-start gap-6 h-fit">
            <div
              data-aos="fade-right"
              className="flex flex-row items-start gap-3"
            >
              <div className="text-xl font-semibold leading-8">1.</div>
              <div className="flex flex-col">
                <p className="flex justify-start text-2xl font-semibold leading-8">
                  Create a Wallet
                </p>
                <p className=" leading-[21px] text-left text-[rgb(150,208,255)]">
                  Download Phantom wallet from the app store or google play
                  store. Desktop users, download phantom extension from&nbsp;
                  <a
                    href="https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa"
                    target="_blank"
                  >
                    <span className="text-[rgb(235,188,88)]">phantom.app</span>
                  </a>
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="flex flex-row items-start gap-3"
            >
              <div className="text-xl font-semibold leading-8">2.</div>
              <div className="flex flex-col">
                <p className="flex justify-start text-2xl font-semibold leading-8">
                  Get some USDT
                </p>
                <p className=" leading-[21px] text-left text-[rgb(150,208,255)]">
                  Have some USDT in your wallet to exchange for $OXA and also have a small amount of Sol for gas fees&nbsp;
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="flex flex-row items-start gap-3"
            >
              <div className="text-xl font-semibold leading-8">3.</div>
              <div className="flex flex-col">
                <p className="flex justify-start text-2xl font-semibold leading-8">
                  Connect to OXA presale Website
                </p>
                <p className="leading-[21px] text-left text-[rgb(150,208,255)]">
                  Enter the amount of $OXA token you wish to purchase. Our
                  platform will display the corresponding transaction details,
                  including the current exchange rate and any associated fees.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="flex flex-row items-start gap-3"
            >
              <div className="text-xl font-semibold leading-8">4.</div>
              <div className="flex flex-col">
                <p className="flex justify-start text-2xl font-semibold leading-8">
                  Confirm the Transaction
                </p>
                <p className=" leading-[21px] text-left text-[rgb(150,208,255)]">
                  Review the transaction details and verify that everything is
                  accurate. Once you are satisfied, confirm the transaction
                  through your connected wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowTo
