import React, { useEffect } from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { WalletProvider } from '@solana/wallet-adapter-react'
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets'
import { WalletConnectProvider } from './providers/WalletConnectProvider'
import '@solana/wallet-adapter-react-ui/styles.css'
import Navbar from './layouts/navbar'
import PresalePart from './pages/presalePart'
import Claim from './pages/claim'
import ThemeContext from './context/themeContext'

import './App.css'
import SOL from './assets/img/sol.svg'
import USDC from './assets/img/usdc.svg'
import USDT from './assets/img/usdt.png'
import JUP from './assets/img/jup.svg'
import Dive from './pages/dive'
import Price from './pages/price'
import Tokenomics from './pages/tokenomics'
import HowTo from './pages/howTo'
import Copyright from './pages/copyright'
import AOS from 'aos'
import 'aos/dist/aos.css'

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function App() {
  const tokens = [
    // { ft: "SOL", icon: SOL },
    // { ft: "JUP", icon: JUP },
    { ft: 'USDT', icon: USDT },
    // { ft: "USDT", icon: USDT },
  ]

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  })

  const queryParameters = new URLSearchParams(window.location.search)
  const ref = queryParameters.get("ref")

  if (ref) {
    setCookie('ref', ref, 10)
  }

  return (
    // <div
    // 	className='App bg-[#131444] bg-center bg-cover'
    // 	style={{ backgroundImage: "url('/assets/img/pattern.png')" }}>
    <div className="App bg-[rgb(4,0,38)] bg-center bg-cover">
      <ThemeContext.Provider value={tokens}>
        <WalletConnectProvider>
          <Navbar></Navbar>
          <div className="flex flex-col ">
            <PresalePart />
            <Claim />
            <Dive />
          </div>
          <Price />
          <div className="flex flex-col ">
            <Tokenomics />
            <HowTo />
          </div>
          <Copyright />
          <ToastContainer
            autoClose={3000}
            draggableDirection="x"
            toastStyle={{ backgroundColor: 'rgba(2,153,255)', color: 'white' }}
          />
        </WalletConnectProvider>
      </ThemeContext.Provider>
    </div>
  )
}

export default App
