
import './layouts.css';
import { toast } from 'react-toastify';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';


const Navbar = () => {
  const { select, wallets, publicKey, disconnect } = useWallet()

  const onWalletConnect = () => {
    if (!publicKey) {
      const installedWallets = wallets.filter(
        (wallet) => wallet.readyState === 'Installed'
      )
      if (installedWallets.length <= 0) {
        toast.warning('Phantom wallet is not installed yet.')
        return
      }
      select(wallets[0].adapter.name)
    } else {
      disconnect()
    }
  }

  return (
    <div className="flex flex-row justify-center w-full navbar">
      <div className="h-[64px] w-[80%] flex flex-row gap-2 items-center justify-between z-50  ">
        <div className="h-10 flex flex-row items-center gap-2.5">
          <img src="/assets/icon/mark.avif" className="w-[50px]" />
          <p className="text-[23px] font-semibold text-[#EABB57] font-[montserrat] max-sm:hidden">
            ONYX <span className="text-white">ARCHES</span>
          </p>
        </div>
        <div className='h-9 flex flex-row items-center justify-center'>
				<WalletMultiButton />
				</div>
      </div>
    </div>
  )
}


export default Navbar
